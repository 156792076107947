import React, { Fragment, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getCmsPage } from '../../actions/cmsActions';

function CmsPage({ match }) {
	const dispatch = useDispatch();
	// let { page_url } = useParams();
	// alert(match.params.page_url)
	let pageUrl = match.params.page_url;
	useEffect(
		() => {
			dispatch(getCmsPage(pageUrl));
		},
		[ dispatch, pageUrl ]
	);

	const { cmspage } = useSelector((state) => state);

	return (
		<Fragment>
			<section
				className="page-banner-sec text-center"
				style={{
					backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`
				}}
			>
				<div className="container">
					<ul className="clearfix paginations-cust">
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>{cmspage.staticpage ? cmspage.staticpage.page_title : ''}</li>
					</ul>
					<h1>{cmspage.staticpage ? cmspage.staticpage.page_title : ''}</h1>
				</div>
			</section> 
			<section className="cms-content-sec">
				<div className="container">
					{cmspage.page_loading ? (
						'Loading'
					) : (
						<Fragment>
							{cmspage.staticpage ? (
								<div dangerouslySetInnerHTML={{ __html: cmspage.staticpage.content }} />
							) : (
								''
							)}
						</Fragment>
					)}
				</div>
			</section>
		</Fragment>
	);
}

export default CmsPage;
