import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getAllExamTypes } from "../../actions/commonActions";
import { getUserSubscription } from "../../actions/subscriptionActions";
import Loader from "../Loader";
import { USER_SUBSCRIPTION_RESET } from "../../constants/subscriptionConstants";
import './Dashboard.css';

const Dashboard = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [examType, setExamType] = useState("");
  const [examId, setExamId] = useState("");
  const [examName, setExamName] = useState("");
  const [examSlug, setExamSlug] = useState("");
  const [examLogo, setExamLogo] = useState("");
  const [maximumSubjects, setMaximumSubjects] = useState(0);
  const [examationType, setExamationType] = useState("");
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { userSubscription } = useSelector((state) => state.examSubscriptions);


  useEffect(() => {
    dispatch(getAllExamTypes());
    dispatch({
      type: USER_SUBSCRIPTION_RESET
    });
    setExamType(localStorage.removeItem("RegSubjects"));
    setExamId(localStorage.getItem("examId"));
    setExamName(localStorage.getItem("examName"));
    setExamSlug(localStorage.getItem("examSlug"));
    setExamLogo(localStorage.getItem("logo"));
  }, [dispatch, alert, isAuthenticated, user]);

  useEffect(() => {
    const examIdd = localStorage.getItem("examId");
    const userIdd = user?._id

    if (examId && userIdd) {
      dispatch(getUserSubscription(userIdd, examIdd));
    }

    return () => {

    }
  }, [])

  const { allExamTypes, eloading } = useSelector((state) => state.commonExams);

  const onChange = (e) => {
    setExamType(e.target.value);
    setExamName(e.target.dataset.name);
    setExamSlug(e.target.dataset.slug);
    localStorage.setItem("logo", e.target.dataset.logo)
    setExamLogo(e.target.dataset.logo);
    setMaximumSubjects(e.target.dataset.subjectcount);
    localStorage.setItem('maximumSubjects', e.target.dataset.subjectcount)
    localStorage.setItem("examinationType", e.target.dataset.type);
    setExamationType(e.target.dataset.type);
    if (e.target.dataset.slug !== 'post-utme') {
      dispatch(getUserSubscription(user._id, e.target.value));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (examType !== "" && examType !== null && examType !== undefined) {
      localStorage.setItem("examId", examType);
      localStorage.setItem("examSlug", examSlug);
      localStorage.setItem("examName", examName);
      localStorage.setItem("logo", examLogo)
      // localStorage.setItem("userSubscription", userSubscription._id);

      const maxSubjects = localStorage.getItem("maximumSubjects");

      if (userSubscription !== undefined && userSubscription !== null) {
        if (examName === "JSCE (BECE)") {
          localStorage.setItem("userSubscription", userSubscription._id);
          history.push(`/course-subjects`);
        } else {
          localStorage.setItem("userSubscription", userSubscription._id);

          history.push(`/categories`);
        }
      } else if (examName === 'POST UTME') {
        history.push(`/university`);
      } else {
        history.push(`/subscription`);
      }
    } else {
      alert.error("Please select exam type to proceed.");
      return false;
    }
  };

  return (
    <Fragment>
      <div className="right-auth-landing">
        <div className="main-view-content">
          <div className="all-ourt-style w-80-cust work-info">
            <div className="all-heads">
              <h3>Dashboard</h3>
            </div>
            {/* <section className="subscription-page"> */}
            <div className="container">
              <div className="end-plan">
                <h1>Select Exam Type</h1>
              </div>
              <div className="custom-radios-plan examtype-spaner">
                {eloading ? (
                  <Loader />
                ) : (
                  <div className="wrapper-dashboard clearfix">
                    {allExamTypes?.length > 0 &&
                      allExamTypes.map((item, key) => {
                        const indexId = key + 1;
                        return (
                          <Fragment key={indexId}>
                            <input
                              type="radio"
                              name="select"
                              id={`option-${indexId}`}
                              value={item._id}
                              data-name={item.title}
                              data-slug={item.slug}
                              data-type={item.examType}
                              data-subjectcount={item.maximumSubjects}
                              data-logo={item.logo}
                              checked={examType === item._id}
                              onChange={onChange}
                              disabled={!item?.status}
                            />
                            <label
                              htmlFor={`option-${indexId}`}
                              className={`option option-${indexId}`}
                            >
                              <div className="dot"></div>
                              <div className="extype">
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/exams/${item.logo}`}
                                  alt="exam-logo"
                                />
                              </div>
                              <span>{item.title}</span>
                              {item.video_link && (
                                <p>
                                  <a
                                    style={{ color: examType === item._id ? "white" : "#31B0E7" }}
                                    href={item.video_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Show Video Guide
                                  </a>
                                </p>
                              )}
                              {!item?.status && <p>Coming Soon</p>}
                            </label>
                          </Fragment>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className="next-prev-steps text-center">
                <button type="submit" className="chose-more" onClick={submitHandler}>
                  Next <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
            {/* </section> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
