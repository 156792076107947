import React, { Fragment } from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <Fragment>
            <footer className="main-footer">
                {/*<div className="pull-right hidden-xs">
                    <b>Version</b> 2.4.13
                </div>*/}
                <strong>Copyright &copy; 2020-{currentYear} <a href="https://onetimesuccess.com">One Time Success</a>.</strong> All rights
                reserved.
            </footer>  

        </Fragment>  
    );
}

export default Footer;