import React, { Fragment } from 'react';

import './QuestionForm.css';
import { Editor } from '@tinymce/tinymce-react';

const QuestionForm = (props) => {
	const currentSubject = props.subject;
	const isAddButtonDisabled = props.formList.length >= 5;
	// alert(currentSubject);
	return props?.formList?.map((val, idx) => {
		let questionTitle = `questionTitle-${idx}`,
			option1 = `option1-${idx}`,
			option2 = `option2-${idx}`,
			option3 = `option3-${idx}`,
			option4 = `option4-${idx}`,
			option5 = `option5-${idx}`,
			rightAnswer = `rightAnswer-${idx}`,
			answerDesc = `answerDesc-${idx}`;
		// index = val.index;
		// alert(val.rightAnswer)
		return (
			<Fragment key={val.index}>
				<div className="question-row">
					{props.passage_check && (
						<div className="form-group row">
							<div className="col-md-12">
								<label htmlFor={questionTitle}>Question {idx + 1}</label>

								{/* <input
									type="text"
									name="questionTitle"
									className="form-control"
									id={questionTitle}
									data-id={idx}
									placeholder="Enter question"
									onChange={props.handleChange}
								// value={val.questionTitle ? val.questionTitle : ""}
								/> */}

								<Editor
									id={`${idx}-questionTitle`}

									apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
									init={{
										height: 200,
										menubar: false,
										plugins: [
											"advlist autolink lists link image imagetools charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount",
										],
										external_plugins: {
											tiny_mce_wiris:
												'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
										},
										toolbar:
											// "undo redo | formatselect  " +
											// "bold italic backcolor | alignleft aligncenter MathType" +
											// "bullist numlist outdent indent | " +
											// "removeformat | help",
											"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
											"undo redo | formatselect " +
											"bold italic backcolor | alignleft aligncenter MathType" +
											"bullist numlist outdent indent | " +
											"removeformat | help",
										htmlAllowedTags: ['.*'],
										htmlAllowedAttrs: ['.*'],
										extended_valid_elements: '*[.*]',
										paste_data_images: true,
										file_picker_types: 'image',

										content_style:
											"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
									}}
									onEditorChange={(txt, editor) =>
										props.changeLinkedQuestionText("questionTitle", editor)
									}
								/>
							</div>
						</div>
					)}

					<div className="form-group row">
						<div className="col-md-6">
							<label htmlFor={option1}>Option 1</label>
							{/* <input
								type="text"
								name="option1"
								className="form-control"
								id={option1}
								data-id={idx}
								placeholder="Enter option 1"
								onChange={props.handleChange}
								required
							//value={val.option1 ? val.option1 : ""}
							/> */}
							<Editor
								// id={`${idx}`}
								id={`${idx}-option1`}

								apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
								init={{
									height: 150,
									width: 450,
									menubar: false,
									plugins: [
										"advlist autolink lists link image imagetools charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media table paste code help wordcount",
									],
									external_plugins: {
										tiny_mce_wiris:
											'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
									},
									toolbar:
										// "undo redo | formatselect  " +
										// "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										// "bullist numlist outdent indent | " +
										// "removeformat | help",
										"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
										"undo redo | formatselect " +
										"bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										"bullist numlist outdent indent | " +
										"removeformat | help",
									htmlAllowedTags: ['.*'],
									htmlAllowedAttrs: ['.*'],
									extended_valid_elements: '*[.*]',
									paste_data_images: true,
									file_picker_types: 'image',
									paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

									content_style:
										"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={(txt, editor) =>
									props.changeLinkedQuestionText("option1", editor)
								}
							/>



							<input
								type="checkbox"
								name={rightAnswer}
								id={`${option1}-${rightAnswer}`}
								data-id={idx}
								value={1}
								onChange={props.handleAnswerChange}
								checked={val.rightAnswer == 1}
							/>
							<label htmlFor={`${option1}-${rightAnswer}`} > &nbsp;Correct Answer</label>
						</div>

						<div className="col-md-6">
							<label htmlFor="option_2">Option 2.</label>
							{/* <input
								type="text"
								name="option2"
								className="form-control"
								id={option2}
								data-id={idx}
								placeholder="Enter Option 2"
								onChange={props.handleChange}
								required
							// value={val.option2}
							/> */}
							<Editor
								id={`${idx}-option2`}
								data={`${idx}`}
								apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
								init={{
									height: 150,
									width: 450,
									menubar: false,
									plugins: [
										"advlist autolink lists link image imagetools charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media table paste code help wordcount",
									],
									external_plugins: {
										tiny_mce_wiris:
											'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
									},
									toolbar:
										// "undo redo | formatselect  " +
										// "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										// "bullist numlist outdent indent | " +
										// "removeformat | help",
										"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
										"undo redo | formatselect " +
										"bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										"bullist numlist outdent indent | " +
										"removeformat | help",
									htmlAllowedTags: ['.*'],
									htmlAllowedAttrs: ['.*'],
									extended_valid_elements: '*[.*]',
									paste_data_images: true,
									file_picker_types: 'image',
									paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

									content_style:
										"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={(txt, editor) =>
									props.changeLinkedQuestionText("option2", editor)
								}
							/>
							<input
								type="checkbox"
								// id="option_2_answer"
								name={rightAnswer}
								id={`${option2}-${rightAnswer}`}
								data-id={idx}
								value={2}
								onChange={props.handleAnswerChange}
								checked={val.rightAnswer == 2}

							/>
							<label htmlFor={`${option2}-${rightAnswer}`} > &nbsp;Correct Answer</label>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-6">
							<label htmlFor="option_3">Option 3</label>
							{/* <input
								type="text"
								name="option3"
								className="form-control"
								id={option3}
								data-id={idx}
								placeholder="Enter option 3"
								onChange={props.handleChange}
								required
							//value={val.option3}
							/> */}
							<Editor
								id={`${idx}-option3`}
								apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
								init={{
									height: 150,
									width: 450,
									menubar: false,
									plugins: [
										"advlist autolink lists link image imagetools charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media table paste code help wordcount",
									],
									external_plugins: {
										tiny_mce_wiris:
											'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
									},
									toolbar:
										// "undo redo | formatselect  " +
										// "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										// "bullist numlist outdent indent | " +
										// "removeformat | help",
										"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
										"undo redo | formatselect " +
										"bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										"bullist numlist outdent indent | " +
										"removeformat | help",
									htmlAllowedTags: ['.*'],
									htmlAllowedAttrs: ['.*'],
									extended_valid_elements: '*[.*]',
									paste_data_images: true,
									file_picker_types: 'image',
									paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

									content_style:
										"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={(txt, editor) =>
									props.changeLinkedQuestionText("option2", editor)
								}
							/>
							<input
								type="checkbox"
								// id="option_3_answer"
								name={rightAnswer}
								id={`${option3}-${rightAnswer}`}
								data-id={idx}
								value={3}
								onChange={props.handleAnswerChange}
								checked={val.rightAnswer == 3}
							/>
							<label htmlFor={`${option3}-${rightAnswer}`} > &nbsp;Correct Answer</label>
						</div>
						<div className="col-md-6">
							<label htmlFor="option_2">Option 4</label>
							{/* <input
								type="text"
								name="option4"
								className="form-control"
								id={option4}
								data-id={idx}
								placeholder="Enter Option 4"
								onChange={props.handleChange}
								required
							//value={val.option4}
							/> */}
							<Editor
								id={`${idx}-option4`}
								apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
								init={{
									height: 150,
									width: 450,
									menubar: false,
									plugins: [
										"advlist autolink lists link image imagetools charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media table paste code help wordcount",
									],
									external_plugins: {
										tiny_mce_wiris:
											'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
									},
									toolbar:
										// "undo redo | formatselect  " +
										// "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										// "bullist numlist outdent indent | " +
										// "removeformat | help",
										"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
										"undo redo | formatselect " +
										"bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										"bullist numlist outdent indent | " +
										"removeformat | help",
									htmlAllowedTags: ['.*'],
									htmlAllowedAttrs: ['.*'],
									extended_valid_elements: '*[.*]',
									paste_data_images: true,
									file_picker_types: 'image',
									paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

									content_style:
										"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={(txt, editor) =>
									props.changeLinkedQuestionText("option2", editor)
								}
							/>

							<input
								type="checkbox"
								// id="option_4_answer"
								name={rightAnswer}
								id={`${option4}-${rightAnswer}`}
								data-id={idx}
								value={4}
								onChange={props.handleAnswerChange}
								checked={val.rightAnswer == 4}
							/>
							<label htmlFor={`${option4}-${rightAnswer}`} > &nbsp;Correct Answer</label>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-12">
							<label htmlFor="option_2">Option 5</label>
							{/* <input
								type="text"
								name="option5"
								className="form-control"
								id={option5}
								data-id={idx}
								placeholder="Enter Option 5"
								onChange={props.handleChange}
							//value={val.option5}
							/> */}
							<Editor
								id={`${idx}-option5`}
								apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
								init={{
									height: 150,
									width: 450,
									menubar: false,
									plugins: [
										"advlist autolink lists link image imagetools charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media table paste code help wordcount",
									],
									external_plugins: {
										tiny_mce_wiris:
											'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
									},
									toolbar:
										// "undo redo | formatselect  " +
										// "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										// "bullist numlist outdent indent | " +
										// "removeformat | help",
										"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
										"undo redo | formatselect " +
										"bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										"bullist numlist outdent indent | " +
										"removeformat | help",
									htmlAllowedTags: ['.*'],
									htmlAllowedAttrs: ['.*'],
									extended_valid_elements: '*[.*]',
									paste_data_images: true,
									file_picker_types: 'image',
									paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

									content_style:
										"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={(txt, editor) =>
									props.changeLinkedQuestionText("option2", editor)
								}
							/>
							<input
								type="checkbox"
								// id="option_4_answer"
								name={rightAnswer}
								id={`${option5}-${rightAnswer}`}
								data-id={idx}
								value={5}
								onChange={props.handleAnswerChange}
								checked={val.rightAnswer == 5}
							/>
							<label htmlFor={`${option5}-${rightAnswer}`} > &nbsp;Correct Answer</label>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-6">
							<label htmlFor="description">Description</label>
							{/* <textarea
								type="text"
								name="answerDesc"
								className="form-control"
								id={answerDesc}
								data-id={idx}
								placeholder="Enter the description for correct answer"
								onChange={props.handleChange}
								required
							//value={val.answerDesc}
							/> */}
							<Editor
								id={`${idx}-answerDesc`}
								apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
								init={{
									height: 150,
									width: 450,
									menubar: false,
									plugins: [
										"advlist autolink lists link image imagetools charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media table paste code help wordcount",
									],
									external_plugins: {
										tiny_mce_wiris:
											'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
									},
									toolbar:
										// "undo redo | formatselect  " +
										// "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										// "bullist numlist outdent indent | " +
										// "removeformat | help",
										"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
										"undo redo | formatselect " +
										"bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
										"bullist numlist outdent indent | " +
										"removeformat | help",
									htmlAllowedTags: ['.*'],
									htmlAllowedAttrs: ['.*'],
									extended_valid_elements: '*[.*]',
									paste_data_images: true,
									file_picker_types: 'image',
									paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

									content_style:
										"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={(txt, editor) =>
									props.changeLinkedQuestionText("option2", editor)
								}
							/>
						</div>
						{true && (
							<div className="col-md-6">
								<br />
								{/* {idx === 0 ? ( */}
								{idx == props?.formList?.length - 1 && <button
									onClick={() => props.add()}
									style={{ margin: '12px' }}
									type="button"
									className="btn btn-primary pull-right"
									title="Add more question for this passage"
									disabled={isAddButtonDisabled || !props.passage_check}
								>
									<i className="fa fa-plus-circle" aria-hidden="true" />
								</button>}
								<button
									style={{ margin: '12px' }}
									className="btn btn-danger pull-right"
									onClick={() => props.delete(val)}
									title="Delete"
									disabled={idx == 0 || !props.passage_check}
								>
									<i className="fa fa-minus" aria-hidden="true" />
								</button>
								{/* )} */}
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	});
};

export default QuestionForm;


