import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Editor } from '@tinymce/tinymce-react';

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  addInstruction,
  clearErrors,
} from "../../../actions/adminInstructionActions";
import { ADD_INSTRUCTION_RESET } from "./../../../constants/adminInstructionConstants";

import Loader from "../../Loader";

import "./Instruction.css";

const AddInstruction = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [instruction, setInstruction] = useState({
    content: "",
    status: false
  });
  const [errorData, setErrorData] = useState({});

  const { content, status } = instruction;

  const { isInstructionAdded, iLoading, error, errors } = useSelector(
    (state) => state.instructionDetail
  );

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isInstructionAdded === true && isInstructionAdded != undefined) {
      alert.success("Instruction successfully added.");
      history.push("/admin/exam-instructions");
      dispatch({
        type: ADD_INSTRUCTION_RESET,
      });
    }
  }, [dispatch, isInstructionAdded, alert, error, errors]);

  const changeEditorText = (content) => {
		setInstruction({
      ...instruction,
      content: content,
    });
	};
  
  const onChange = (e) => {
    if (e.target.name === "status") {
      setInstruction({
        ...instruction,
        status: !status,
      });
    } else {
      setInstruction({
        ...instruction,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addInstruction(content, status));
  };

  return (
    <Fragment>
      {iLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Instruction</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/instructions">
                    <i className="fa fa-users"></i> Instructions
                  </Link>
                </li>
                <li className="active">Add Instruction</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-content">Add Instruction</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="content">Instruction</label>

                        <Editor
													apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
													init={{
														height: 200,
														menubar: false,
														external_plugins: {},
														plugins: [
															'advlist autolink lists link image imagetools charmap print preview anchor',
															'searchreplace visualblocks code fullscreen',
															'insertdatetime media table paste code help wordcount'
														],
														toolbar:
															'undo redo | link image | formatselect ' +
															'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
															'bullist numlist outdent indent | ' +
															'removeformat | code | help',
														content_style:
															'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
														file_picker_types: 'image'
													}}
													value={content}
													// initialValue={question}
													onEditorChange={(newText) => changeEditorText(newText)}
												/>
                        {/*<ReactSummernote
                          value={content}
                          options={{
                            lang: "en-US",
                            height: 250,
                            dialogsInBody: false,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["fontname", ["fontname"]],
                              ["color", ["color"]],
                              ["para", ["ul", "ol", "listStyles", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "picture", "video"]],
                              ["view", ["fullscreen", "codeview"]],
                            ],
                          }}
                          onChange={onEditorDataChange}
                          onImageUpload={onImageUpload}
                        />*/}
                        {errorData.content ? (
                          <span className="text-danger">{errorData.content}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status</label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          name="status"
                          checked={status}
                          value={status}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddInstruction;
