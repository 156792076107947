import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getExamDetail,
  updateExam,
  clearErrors,
} from "../../../actions/adminExamActions";
import { UPDATE_EXAM_RESET } from "./../../../constants/adminExamConstants";

import Loader from "../../Loader";
import "./Exam.css";

import { Editor } from '@tinymce/tinymce-react';

const EditExam = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [examId, setExamId] = useState("");
  const [title, setTitle] = useState("");
  const [videoLink, setVideoLink] = useState("")
  const [maximumSubjects, setMaximumSubjects] = useState("");
  const [yearOfExamination, setYearOfExamination] = useState("");
  const [examType, setExamType] = useState("");
  const [timeHour, setTimeHour] = useState("");
  const [time, setTime] = useState("");
  const [examInstructions, setExamInstructions] = useState("");
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [imageName, setImageName] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const { isUpdated, user_loading, error } = useSelector(
    (state) => state.exams
  );

  const changeEditorText = (content) => {
		setExamInstructions(content);
	};

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getExamDetail(currentUserId));
  }, [dispatch]);

  const { exam } = useSelector((state) => state.examDetail);

  useEffect(() => {
    if (exam) {
      setExamId(exam._id);
      setTitle(exam.title);
      setMaximumSubjects(exam.maximumSubjects);
      setYearOfExamination(exam.yearOfExamination);
      setExamType(exam.examType);
      setTimeHour(exam.timeHour);
      setTime(exam.time);
      setExamInstructions(exam.examInstructions);
      setStatus(exam.status);
      setLogo(exam.logo);
      setOldImage(exam.logo);
      setVideoLink(exam.video_link)
      setImagePreview(
        process.env.REACT_APP_PUBLIC_URL + "/assets/img/exams/" + exam.logo
      );
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Exam updated successfully");
      dispatch(getExamDetail(exam._id));
      history.push(`/admin/exams`);
      dispatch({
        type: UPDATE_EXAM_RESET,
      });
    }
  }, [dispatch, exam, alert, error, history, isUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateExam(
        examId,
        title,
        maximumSubjects,
        yearOfExamination,
        examType,
        timeHour,
        time,
        examInstructions,
        status,
        oldImage,
        logo,
        imageName,
        videoLink
      )
    );
  };

  const onChange = (e) => {
    let file = e.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setLogo("");
      setImageName("");
      alert.error("Please select valid image.");
      //remove preview picture if any
      const preview = document.getElementById("img-preview");
      preview.src = "";
      return false;
    }

    const reader = new FileReader();

    reader.onload = function (readerEvt) {
      // The file's text will be printed here
      //console.log(readerEvt.target.result);
      let binaryString = readerEvt.target.result;
      let base64String = btoa(binaryString);
      setLogo(base64String);
      setImageName(file.name);

      //preview picture
      const preview = document.getElementById("img-preview");
      // console.log('banary image', base64String);
      preview.src = "data:image/png;base64," + base64String;
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Exam</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/exams">
                    <i className="fa fa-users"></i> Exams
                  </Link>
                </li>
                <li className="active">Edit Exam</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Exam</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title"
                          placeholder="Enter Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="logo">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={onChange}
                          accept={`.jpeg, .jpg, .png`}
                        />
                        <div>
                          <img id="img-preview" src={imagePreview} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="status">Link <i>(Youtube link prefared to show video guide to user)</i> </label>
                        <input
                          type="text"
                          className="form-control"
                          id="video_link"
                          name="video_link"
                          value={videoLink}
                          onChange={(e) => setVideoLink(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="maximumSubjects">
                          Maximum Subjects
                        </label>
                        <select
                          className="form-control"
                          name="maximumSubjects"
                          value={maximumSubjects}
                          onChange={(e) => setMaximumSubjects(e.target.value)}
                        >
                          <option value="">Count</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="yearOfExamination">
                          Year Of Examination
                        </label>
                        <select
                          className="form-control"
                          name="yearOfExamination"
                          value={yearOfExamination}
                          onChange={(e) => setYearOfExamination(e.target.value)}
                        >
                          <option value="">Year</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="examType">Exam Type</label>
                        <select
                          className="form-control"
                          name="examType"
                          value={examType}
                          onChange={(e) => setExamType(e.target.value)}
                        >
                          <option value="">Type</option>
                          <option value="objective">Only objective</option>
                          <option value="theory">Only theory</option>
                          <option value="both">Both theory & Objective</option>
                        </select>
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="timeLimit">Time Limit</label>

                        <select
                          className="form-control"
                          name="timeHour"
                          value={timeHour}
                          onChange={(e) => setTimeHour(e.target.value)}
                        >
                          <option value="">Hour</option>
                          {[...Array(24)].map((e, i) => (
                            <option value={i}>{i}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="timeLimit">&nbsp;</label>
                        <select
                          className="form-control"
                          name="time"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        >
                          <option value="">Time</option>
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="examInstructions">
                          Exam Instructions
                        </label>
                        <Editor
													apiKey="nag9rfyktzweza49mzb77l9378ilslwdo58xufcns615clff"
													init={{
														height: 200,
														menubar: false,
														external_plugins: {},
														plugins: [
															'advlist autolink lists link image imagetools charmap print preview anchor',
															'searchreplace visualblocks code fullscreen',
															'insertdatetime media table paste code help wordcount'
														],
														toolbar:
															'undo redo | link image | formatselect ' +
															'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
															'bullist numlist outdent indent | ' +
															'removeformat | code | help',
														content_style:
															'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
														file_picker_types: 'image'
													}}
													value={examInstructions}
													// initialValue={question}
													onEditorChange={(newText) => changeEditorText(newText)}
												/>
                        
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="status">Status </label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="checkbox"
                          className="form-check-input"
                          id="status"
                          checked={status}
                          value={status}
                          onChange={(e) => setStatus(!status)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditExam;
